<template>
  <span class="analytic-accounting-detail" v-if="hasPerm('payments.view_analyticaccount')">
    <b-modal dialog-class="modal-xl"
      :id="modalId"
      ok-only
      ok-variant="primary"
      ok-title="Fermer"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-exchange-alt"></i> Détail analytique: {{ analyticAccount.name }} {{ schoolYear.name }}</b>
      </template>
      <b-row>
        <b-col>
          <loading-gif :loading-name="loadingName"></loading-gif>
          <div v-if="errorText" class="error-text">
            {{ errorText }}
          </div>
          <div v-if="!isLoading(loadingName)">
            <strong>
              {{ label }}
              <span v-if="usageDate">
                <span v-if="usageStartDate && usageEndDate">
                  du {{ dateToString(usageStartDate) }} au {{ dateToString(usageEndDate) }}:
                </span>
                <span v-else-if="usageStartDate">
                  depuis le {{ dateToString(usageStartDate) }}:
                </span>
                <span v-else-if="usageEndDate">
                  jusqu'au {{ dateToString(usageEndDate) }}:
                </span>
              </span>
              <span v-else>du {{ dateToString(startDate) }} au {{ dateToString(endDate) }}:</span>
              {{ total | currency }}
            </strong>
            <table class="table table-bordered table-striped">
              <tr v-for="item of items" :key="item.index">
                <td>{{ item.index + 1 }}</td>
                <td>
                  <a href @click.prevent="showEntitySidebar(item.entity)">
                    <span class="family-numbers">{{ item.entity.id }}</span> {{ item.entity.name }}
                  </a>
                </td>
                <td>{{ dateToString(item.dateOn) }}</td>
                <td><span v-if="item.invoice">Facture {{ item.invoice.getNumber() }}</span></td>
                <td>{{ getValue(item, fieldName) | currency }}</td>
              </tr>
            </table>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import LoadingGif from '@/components/Controls/LoadingGif'
import { makeInvoice } from '@/types/payments'
import { makeEntity } from '@/types/people'
import { BackendMixin } from '@/mixins/backend'
import { mapMutations } from 'vuex'
import { BackendApi } from '@/utils/http'
import { sum } from '@/utils/math'
import { dateToString } from '@/filters/texts'

export default {
  name: 'analytic-accounting-detail-modal',
  components: { LoadingGif, },
  mixins: [BackendMixin],
  props: {
    analyticAccount: Object,
    schoolYear: Object,
    fieldName: String,
    startDate: String,
    endDate: String,
    usageStartDate: String,
    usageEndDate: String,
    valueOn: String,
    modalId: String,
    entityNumber: String,
    url: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      items: [],
      loadingName: 'analytic-accounting-detail',
      selectedAnalytic: null,
      selectedSchoolYear: null,
      selectedFieldName: '',
      selectedStartDate: '',
      selectedEndDate: '',
      errorText: '',
    }
  },
  computed: {
    total() {
      return sum(this.items.map(item => this.getValue(item, this.fieldName)))
    },
    usageDate() {
      switch (this.fieldName) {
        case 'period_refund_credits':
          return true
        case 'period_used_credits':
          return true
        default:
          return false
      }
    },
    label() {
      switch (this.fieldName) {
        case 'sales_amount':
          return 'Ventes facturées'
        case 'cancelled_sales_amount':
          return 'Ventes annulées'
        case 'no_invoice_amount':
          return 'Ventes à facturer'
        case 'to_be_paid_amount':
          return 'A Payer'
        case 'payment_contributions':
          return 'Paiements numéraires encaissés'
        case 'credit_contributions':
          return 'Paiements en avoir'
        case 'cancellation_credits':
          return 'Avoirs générés'
        case 'remaining_credits':
          return 'Avoirs en cours'
        case 'used_credits':
          return 'Avoirs utilisés'
        case 'refund_credits':
          return 'Avoirs remboursés'
        case 'future_payment_contributions':
          return 'Paiements numéraires en attente'
        case 'period_refund_credits':
          return 'Avoirs remboursés'
        case 'period_used_credits':
          return 'Avoirs utilisés'
        case 'expenses_amount':
          return 'Dépenses'
        default:
          return this.fieldName
      }
    },
  },
  watch: {
    analyticAccount: function() { this.loadItems() },
    schoolYear: function() { this.loadItems() },
    fieldName: function() { this.loadItems() },
    startDate: function() { this.loadItems() },
    endDate: function() { this.loadItems() },
    entityNumber: function(newValue, oldValue) {},
  },
  methods: {
    dateToString,
    ...mapMutations(['startLoading', 'endLoading']),
    getId() {
      return 'bv-modal-analytic-accounting-detail'
    },
    getValue(item, fieldName) {
      return item[fieldName]
    },
    setValue(item, fieldName, value) {
      item[fieldName] = value
    },
    hasChanged() {
      const isDefined = this.startDate && this.endDate && this.fieldName
      if (isDefined) {
        const hasChanged = (
          (this.selectedStartDate !== this.startDate) ||
          (this.selectedEndDate !== this.endDate) ||
          (this.selectedFieldName !== this.fieldName) ||
          (this.selectedAnalytic !== this.analyticAccount) ||
          (this.selectedSchoolYear !== this.schoolYear)
        )
        if (hasChanged) {
          this.selectedStartDate = this.startDate
          this.selectedEndDate = this.endDate
          this.selectedFieldName = this.fieldName
          this.selectedAnalytic = this.analyticAccount
          this.selectedSchoolYear = this.schoolYear
        }
        return hasChanged
      } else {
        return false
      }
    },
    async loadItems() {
      if (this.hasChanged()) {
        this.startLoading(this.loadingName)
        const groupMap = new Map()
        this.items = []
        this.errorText = ''
        try {
          let url = ''
          if (this.url === 'ex') {
            url = '/api/analytic-accounting-ex/?start_date=' + this.startDate + '&end_date=' + this.endDate
            url += '&value_on=' + this.valueOn
          } else if (this.url === 'credits') {
            url = '/api/analytic-accounting-credits/?start_date=' + this.startDate + '&end_date=' + this.endDate
          } else {
            url = '/api/analytic-accounting/?start_date=' + this.startDate + '&end_date=' + this.endDate
          }
          if (this.entityNumber) {
            url += '&entity=' + this.entityNumber
          }
          url += '&attr=' + this.fieldName
          url += '&analytic_account=' + (this.analyticAccount ? this.analyticAccount.id : 0)
          url += '&school_year=' + (this.schoolYear ? this.schoolYear.id : 0)
          if (this.usageDate) {
            url += '&usage_start_date=' + this.usageStartDate
            url += '&usage_end_date=' + this.usageEndDate
          }
          let backendApi = new BackendApi('get', url)
          let resp = await backendApi.callApi()
          for (const elt of resp.data) {
            let key = elt.invoice ? elt.invoice.id : elt.entity.id
            key += dateToString(elt.date_on)
            let item = null
            let value = this.getValue(elt, this.fieldName)
            if (groupMap.has(key)) {
              const itemIndex = groupMap.get(key)
              item = this.items[itemIndex]
              value += this.getValue(item, this.fieldName)
            } else {
              const index = this.items.length
              item = {
                index: index,
                entity: makeEntity(elt.entity),
                dateOn: elt.date_on,
                invoice: elt.invoice ? makeInvoice(elt.invoice) : null,
              }
              this.items.push(item)
              groupMap.set(key, index)
            }
            this.setValue(item, this.fieldName, value)
          }
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
        this.endLoading(this.loadingName)
      }
    },
  },
  mounted() {
  },
}
</script>
<style lang="less">
</style>
